import React from "react";
import { gql } from "@apollo/client";
import { Query, Subscription } from "@apollo/client/react/components";
import styled from "styled-components";

const query = gql`
	query ($permalink: String!) {
		notes(permalink: $permalink) {
			text
			createdAt {
				iso8601
			}
		}
	}
`;

const subscription = gql`
	subscription NoteCreated($permalink: String!) {
		noteCreated(permalink: $permalink) {
			text
			createdAt {
				iso8601
			}
		}
	}
`;

const PostNotes = ({ permalink }) => (
	<Query query={query} variables={{ permalink }}>
		{({ data, loading, error }) => {
			if (!(!loading && data) || !data.notes || data.notes.length === 0) {
				return [];
			}
			return (
				<Subscription
					subscription={subscription}
					variables={{ permalink }}
				>
					{({ data: subscriptionData }) => (
						<div key={"data"}>
							{data.notes
								.concat((subscriptionData || {}).notes || [])
								.map((note) => (
									<div>
										{note.text} - {note.createdAt.iso8601}
									</div>
								))}
						</div>
					)}
				</Subscription>
			);
		}}
	</Query>
);
export default PostNotes;
