import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faNewer from "@fortawesome/fontawesome-free-solid/faFastForward";
import faOlder from "@fortawesome/fontawesome-free-solid/faFastBackward";

import PostFeaturedImage from "./PostFeaturedImage";
import EntryHeader from "./Headers/Entry";
import InsetContent from "./InsetContent";
import NavigationBar from "./NavigationBar";

const StyledLink = styled(Link)`
	text-decoration: none;
	text-shadow: none;
	background: none;

	color: ${(props) => props.theme.titleColor || props.theme.contentColor};
`;

const Footer = styled.footer`
	margin-top: 4em;
	overflow-x: hidden;
	border-top: ${(props) => props.theme.contentTopBorder};
`;

const NavList = styled.ul`
	display: flex;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
	flex-wrap: nowrap;
	margin: 0;
	padding: 0;
`;

const ListItem = styled.li`
	flex: 1;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	height: 16em;
`;

const AtLeastOneImage = ({
	next,
	previous,
	emptyLeftImage,
	emptyRightImage,
}) => (
	<Footer>
		<NavList>
			{previous && (
				<ListItem>
					<StyledLink
						rel="previous"
						title={previous.frontmatter.title}
						to={previous.fields.slug}
					>
						<PostFeaturedImage
							pageWidth={false}
							image={
								previous.frontmatter.image &&
								previous.frontmatter.image.childImageSharp
									.gatsbyImageData
							}
							imageURL={previous.fields.videoThumbnailURL}
							emptyImage={
								emptyLeftImage &&
								emptyLeftImage.childImageSharp.gatsbyImageData
							}
							height={"16em"}
							slug={previous.fields.slug}
							title={previous.frontmatter.title}
							date={previous.frontmatter.date}
							dateISO8601={previous.frontmatter.dateISO8601}
							textSize={"small"}
						/>
					</StyledLink>
				</ListItem>
			)}

			{next && (
				<ListItem>
					<StyledLink
						rel="next"
						title={next.frontmatter.title}
						to={next.fields.slug}
					>
						<PostFeaturedImage
							pageWidth={false}
							image={
								next.frontmatter.image &&
								next.frontmatter.image.childImageSharp
									.gatsbyImageData
							}
							imageURL={next.fields.videoThumbnailURL}
							emptyImage={
								emptyRightImage &&
								emptyRightImage.childImageSharp.gatsbyImageData
							}
							height={"16em"}
							slug={next.fields.slug}
							title={next.frontmatter.title}
							date={next.frontmatter.date}
							dateISO8601={next.frontmatter.dateISO8601}
							textSize={"small"}
						/>
					</StyledLink>
				</ListItem>
			)}
		</NavList>
	</Footer>
);

const NavigationIcon = styled(FontAwesomeIcon)`
	height: 0.75em;
	top: -0.125em;
	position: relative;
	margin-left: 16px;
	margin-right: 16px;
	line-height: 100%;
	display: block;

	@media only screen and (max-width: 450px) {
		text-align: center;
		display: inline;
	}
`;

const NavigationIconContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media only screen and (max-width: 450px) {
		display: block;
		width: 100%;
		text-align: center;
	}
`;

const NavigationLink = styled(Link)`
	max-width: 40%;
	display: flex;
	flex-direction: row;

	@media only screen and (max-width: 450px) {
		flex-direction: column;
		max-width: 100%;
	}
`;

const NavigationTitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media only screen and (min-width: 451px) {
		text-align: ${({ align = "left" }) => align};
	}
`;

const NavBar = styled(NavigationBar)`
	@media only screen and (max-width: 450px) {
		justify-content: center;
	}
`;

const BarContainer = styled.div`
	margin-top: 2em;
`;

const NoImages = ({ next, previous }) => (
	<BarContainer>
		<NavBar center={false} padding="1em 0">
			{previous && (
				<NavigationLink to={previous.fields.slug}>
					<NavigationIconContainer>
						<NavigationIcon icon={faOlder} />
					</NavigationIconContainer>
					<NavigationTitle align={"left"}>
						{previous.frontmatter.title}
					</NavigationTitle>
				</NavigationLink>
			)}
			{next && (
				<NavigationLink to={next.fields.slug}>
					<NavigationTitle align={"right"}>
						{next.frontmatter.title}
					</NavigationTitle>
					<NavigationIconContainer>
						<NavigationIcon icon={faNewer} />
					</NavigationIconContainer>
				</NavigationLink>
			)}
		</NavBar>
	</BarContainer>
);

const PostNavigation = (props) => {
	if (
		(props.next && props.next.frontmatter.image) ||
		(props.previous && props.previous.frontmatter.image)
	) {
		return <AtLeastOneImage {...props} />;
	} else {
		return <NoImages {...props} />;
	}
};
export default PostNavigation;
