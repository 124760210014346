import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SiteNav from "../components/SiteNav";
import SiteFooter from "../components/SiteFooter";

import Video from "../components/Video";
import ReadMoreHTML from "../components/ReadMoreHTML";
import EntryHeader from "../components/Headers/Entry";
import PostFeaturedImage from "../components/PostFeaturedImage";
import ContentTheme from "../components/ContentTheme";
import InsetContent from "../components/InsetContent";
import { TagList, Tag } from "../components/TagList";
import { rhythm, scale } from "../utils/typography";
import PostNavigation from "../components/PostNavigation";
import Sections from "../components/Headers/Sections";
import Author from "../components/Author";
import InStreamElement from "../components/InStreamElement";
import FollowCTA from "../components/CTA/Follow";
import FullBleed from "../components/FullBleed";
import PostNotes from "../components/PostNotes";

const FillerHeaderImage = styled(Img)`
	height: 7em;
`;

const PostWrapper = styled(InsetContent)`
	padding-top: ${(props) => props.hasImage && "2em"};
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	border-top: ${(props) => props.theme.contentTopBorder};
`;

const PhotoImg = styled(FullBleed)`
	margin-bottom: 2em;
	box-shadow: ${(props) => props.theme.contentBoxShadow || "none"};
`;

const BlogPostTemplate = (props) => {
	const post = props.data.markdownRemark;
	const site = get(props, "data.site.siteMetadata");
	const siteTitle = site.title;
	const siteDescription = site.description;
	const siteURL = site.siteUrl;
	const { previous, next } = props.pageContext;
	const emptyHeader = get(props, "data.header");
	const emptyLeft = get(props, "data.left");
	const emptyRight = get(props, "data.right");
	const metaImageSrc = get(
		post,
		"frontmatter.image.childImageSharp.original.src"
	);
	const permalink = get(post, "fields.slug");
	const metaImage = metaImageSrc && siteURL + metaImageSrc;
	return (
		<Layout>
			<ContentTheme key={post.id} layout={post.frontmatter.layout}>
				<div>
					<Article className="h-entry">
						<Helmet>
							<title>{`${post.frontmatter.title} - ${siteTitle}`}</title>
							<meta
								name="description"
								content={post.meta_description}
							/>

							<meta
								itemprop="name"
								content={post.frontmatter.title}
							/>
							<meta
								itemprop="description"
								content={post.full_description}
							/>
							{metaImage && (
								<meta itemprop="image" content={metaImage} />
							)}

							{metaImage && (
								<meta name="twitter:card" content="summary" />
							)}
							{!metaImage && (
								<meta
									name="twitter:card"
									content="summary_large_image"
								/>
							)}

							<meta name="twitter:site" content="@SteveStreza" />
							<meta
								name="twitter:title"
								content={post.frontmatter.title}
							/>
							<meta
								name="twitter:description"
								content={post.twitter_description}
							/>
							<meta
								name="twitter:creator"
								content="@SteveStreza"
							/>
							{metaImage && (
								<meta
									name="twitter:image:src"
									content={metaImage}
								/>
							)}

							<meta
								property="og:title"
								content={post.frontmatter.title}
							/>
							<meta property="og:type" content="article" />
							<meta
								property="og:url"
								content={siteURL + post.fields.slug}
							/>
							{metaImage && (
								<meta property="og:image" content={metaImage} />
							)}
							<meta
								property="og:description"
								content={post.full_description}
							/>
							<meta property="og:site_name" content={siteTitle} />
							<meta
								property="article:published_time"
								content={post.frontmatter.dateISO8601}
							/>
							<meta
								property="article:modified_time"
								content={post.frontmatter.dateISO8601}
							/>
							{(post.frontmatter.tags || []).map((tag) => (
								<meta
									key={"tag-" + tag}
									property="article:tag"
									content={tag}
								/>
							))}
						</Helmet>
						{permalink && (
							<Helmet key="permalink">
								<link
									rel="self"
									href={"https://stevestreza.com" + permalink}
								/>
							</Helmet>
						)}
						{post.frontmatter.image &&
							post.frontmatter.layout !== "photo" && (
								<div>
									<Sections floating={true} />
									<PostFeaturedImage
										offsetTop="1em"
										image={
											post.frontmatter.image
												.childImageSharp.gatsbyImageData
										}
										title={post.frontmatter.title}
										date={post.frontmatter.date}
										dateISO8601={
											post.frontmatter.dateISO8601
										}
									/>
								</div>
							)}
						{(!post.frontmatter.image ||
							post.frontmatter.layout === "photo") && (
							<div>
								<SiteNav photo={props.data.photo.small} />
								<PostFeaturedImage
									height={"7em"}
									image={
										emptyHeader.childImageSharp
											.gatsbyImageData
									}
								/>
								<Sections />
								<div style={{ marginTop: "2em" }} />
								{post.frontmatter.layout === "photo" &&
									post.frontmatter.image && (
										<InsetContent>
											<PhotoImg>
												<Img
													image={
														post.frontmatter.image
															.childImageSharp
															.gatsbyImageData
													}
												/>
											</PhotoImg>
										</InsetContent>
									)}
								<EntryHeader
									title={post.frontmatter.title}
									date={
										post.frontmatter.layout !== "photo" &&
										post.frontmatter.date
									}
									dateISO8601={post.frontmatter.dateISO8601}
									link={post.frontmatter.link}
								/>
							</div>
						)}
						<InStreamElement
							display={
								post.frontmatter.image &&
								post.frontmatter.layout !== "photo"
							}
						>
							<Author
								photo={props.data.photo.small}
								inStream={true}
								name={siteTitle}
								bio={siteDescription}
							/>
						</InStreamElement>
						<PostWrapper
							hasImage={
								!!(
									post.frontmatter.image &&
									post.frontmatter.layout !== "photo"
								)
							}
						>
							<span className="e-content">
								{post.frontmatter.video_url && (
									<section>
										<Video
											url={post.frontmatter.video_url}
										/>
									</section>
								)}
								<ReadMoreHTML
									truncate={false}
									link={post.frontmatter.link}
									html={post.html}
								/>
								{post.fields.slug && (
									<PostNotes permalink={post.fields.slug} />
								)}
							</span>
							{post.frontmatter.tags && (
								<TagList>
									{post.frontmatter.tags.map((tag) => (
										<Tag key={tag} tag={tag} />
									))}
								</TagList>
							)}
						</PostWrapper>
						<FollowCTA />
						<PostNavigation
							next={next}
							previous={previous}
							emptyLeftImage={emptyLeft}
							emptyRightImage={emptyRight}
						/>
					</Article>
					<SiteFooter />
				</div>
			</ContentTheme>
		</Layout>
	);
};

export const query = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				description
				author
				siteUrl
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			meta_description: excerpt(pruneLength: 155)
			twitter_description: excerpt(pruneLength: 200)
			full_description: excerpt
			fields {
				slug
			}
			frontmatter {
				title
				layout
				date(formatString: "MMMM DD, YYYY")
				dateISO8601: date(formatString: "YYYY-MM-DD HH:mm:ss.SSSZZ")
				video_url
				categories
				tags
				link
				image {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}

		left: file(relativePath: { eq: "header/left.png" }) {
			...ImageData
		}

		right: file(relativePath: { eq: "header/right.png" }) {
			...ImageData
		}

		photo: file(relativePath: { eq: "photo.jpg" }) {
			small: childImageSharp {
				gatsbyImageData(layout: FIXED, width: 60, height: 60)
			}
		}
	}
`;

export default BlogPostTemplate;
